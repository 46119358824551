<template lang="pug">
v-container
  v-row.justify-center.aling-center
    v-col.px-10
      p(v-html='titulo.titulo').primary--text.text-center.mb-12.
      v-row.justify-center.aling-center
        v-col(cols=3 v-for='{imagen, Nombre, Cv}, k of arreglo' :key='k')
          v-hover( v-slot:default="{ hover }")
            v-card( tile flat :href='Cv' target='_blanck')
              v-img( :src='imagen' :aspect-ratio='7/8' )
                v-expand-transition
                  small(
                    v-if="hover"
                    class="d-flex align-center transition-fast-in-fast-out primary  v-card--reveal white--text text-center"
                    style="height: 100%;"
                    v-html='Nombre')
  .d-flex.flex-column.align-center.mt-12
    v-btn( :href='titulo.vinculo' target='_blank' id="Hb" outlined v-html='titulo.button').button
</template>
<script>
export default {
  props: ['titulo', 'arreglo']
}
</script>
<style >
.colorazultexto{
  color: #003452;
}
.button#Hb {
  background-color: #ffffff;
  color: #d19100;
  border:1px solid #d19100;
}
.button#Hb:hover {
    

  background-color:#003452;
  color: #ffffff;

  border:1px solid #003452;
}
</style>